:root{
  --blue: #263C82;
  --orange: #FF7B31;
  --green: #00BF63;
  --cc1: #826C26;
  --cc2: #31b5ff;
  --cc3: #BF005C;
}

body {
  margin: 0;
  background-color: #000;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Courier Prime', monospace;
  font-weight: 400;
}

::-webkit-scrollbar {
  display: none;
}