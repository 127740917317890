.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% / 6);
}

.tab:hover {
  text-decoration: wavy #02B663 underline;
  color: #02B663;
}

.textTab {
  font-size: 25px;
}