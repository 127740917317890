nav {
  position: relative;
  height: auto;
  padding-right: 0%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  animation: menu1 2s;
}

.navbar-brand {
  font-size: 20px;
}

#btnMenu {
  border-radius: 0;
  font-size: 20px;
  width: 50px;
  height: 50px;
  backdrop-filter: invert(100%);
  color: white;
}

.fa-bars {
  animation: rotate .7s;
}

.fa-xmark {
  animation: rotate .7s reverse;
}

#menuCollapse.d-yes {
  height: calc(100vh - 50px);
  animation: drop .7s;
  transform-origin: top center
}

#menuCollapse.d-no {
  height: 0;
  animation: drop .7s reverse;
  opacity: 0;
  transform: scaleY(0);
  transition: 700ms;
}

@keyframes menu1 {
  0% {
    top: -42px;
  }

  100% {
    top: 0px;
  }
}

@keyframes drop {
  0% {
    transform: scaleY(0);
    height: 0px;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    height: calc(100vh - 41px);
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}