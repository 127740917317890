.project {
  padding: 0;
}

.project:hover {
  scale: 1.1;
  transition: .5s;
}

.al{
  color: white;
  text-decoration: none;
}

.al:hover{
  color: var(--green);
}