p {
  text-align: justify;
}

.bg-1 {
  background-color: var(--blue);
  color: white;
}

.bg-2 {
  background-color: var(--orange);
  color: white;
}

.bg-gradiente {
  background: linear-gradient(var(--blue), var(--cc2), var(--orange), var(--cc1), var(--green), var(--cc3));
}

.bg-1-gradient {
  background: linear-gradient(var(--blue), var(--cc2));
  background-size: 100% 100%;
}

.bg-2-gradient {
  background: linear-gradient(var(--orange), var(--cc1));
  background-size: 100% 100%;
}

.bg-3-gradient {
  background: linear-gradient(var(--green), var(--cc3));
  background-size: 100% 100%;
}

.bg-blur {
  background-color: transparent;
  backdrop-filter: blur(5px);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerY {
  display: flex;
  align-items: center;
}

.centerX {
  display: flex;
  justify-content: center;
}

.b-0 {
  margin: 0;
  padding: 0;
}