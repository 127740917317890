section {
  min-height: 100vh;
  width: 100vw;
  align-items: flex-end;
  justify-content: center;
}

.card {
  margin: 100px 0px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
}