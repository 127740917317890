.leng {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#techs>.leng>img,
#techs>.leng>svg {
  height: 40px;
}

.fa-html5 {
  color: #d6623a;
}

.fa-css3-alt {
  color: #449bd2;
}

.fa-bootstrap {
  color: #6F42C1;
}

.fa-python {
  color: #519aba;
}

.fa-font-awesome {
  color: #228ae6;
}

.fa-sass {
  color: #cc6699;
}

.fa-js {
  color: #f7df1e;
}

.fa-angular {
  color: #dd0031;
}

.fa-react {
  color: #61dafb;
}

.fa-vuejs {
  color: #41b883;
}

.fa-npm {
  color: #cb3837;
}

.fa-yarn {
  color: #368fb9;
}

.fa-node-js {
  color: #78b160;
}

.fa-git-alt {
  color: #f05033
}

#Express {
  filter: invert(100%);
}

.tLeng {
  width: 100%;
}