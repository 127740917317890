#img.img-card-top {
  filter: drop-shadow(13px 13px black);
  animation: changeColor 9s infinite;
}

#info>* {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: red;
  padding-right: 0%;
}

.cat {
  color: greenyellow;
  width: auto;
}


.str {
  color: burlywood;
}

.correo {
  color: burlywood;
}

.json {
  color: dodgerblue;
}

.int {
  color: violet;
}

.list {
  color: gold;
}


@keyframes changeColor {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}