#img {
  animation: aumentar 5s infinite;
  height: 200px;
  width: 200px;
  filter: drop-shadow(13px 13px black);
}

#s0 {
  animation: serv 2.5s infinite alternate-reverse 1s;
}

#s1 {
  animation: serv 2.5s infinite alternate-reverse 2s;
}

#s2 {
  animation: serv 2.5s infinite alternate-reverse 3s;
}


@keyframes serv {
  0% {
    scale: 0;
    opacity: 0;
  }

  100% {
    scale: 1;
    opacity: 1;
  }
}

@keyframes aumentar {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}