.nextSec {
  position: relative;
  bottom: 0px;
  animation: opacity 5s infinite;
  color: white;
  text-decoration: none;
  font-size: 50px;
}

@keyframes opacity {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}